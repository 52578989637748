body {
  margin: 0 !important;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Helvitica","Quicksand", sans-serif
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  height:fit-content !important;
  min-width:320px;
  background: #FFFFFF !important;
}

*{
  font-size:12px;
  box-sizing:border-box;
}

@media screen and (max-width: 1000px) {
  *{
    font-size:12px;
  }
}

html{
  height:100%;
  margin: 0 !important;;
}
#app, #app>div {
  height: 100%
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

video{
  object-fit: scale-down !important;
}
#root {
  width:100%;
  height:100%
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size:0.8rem;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size:0.8rem;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size:0.8rem;
}
:-moz-placeholder { /* Firefox 18- */
  font-size:0.8rem;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.avatar-icon {
  width: 45px;
  height: 45px;
}

.avatar-icon-lg {
  width: 80px;
  height: 80px;
}

.avatar-icon-sm {
  width: 25px;
  height: 25px;
}

.avatar-icon-xs {
  width:20px;
  height:20px;
}


/* This is for table item align and vertical */
@media screen and (max-width: 1000px) {
  .text-position{
    text-align: right;
  }
  .modal-dialog {
    max-width: 800px !important;
    margin: 1.75rem auto;
}
}

a.no-underline:hover,a.no-underline:focus {
  text-decoration: none;
}

button.no-underline:hover,button.no-underline:focus{
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6,h7 {
  margin-bottom:0 !important;
}

button{
  border:0;
  background-color:#fff
}

.table > tbody > tr > td {
  /* vertical-align: middle !important; */
  cursor: pointer;
}

.transition-button-color{
  background-color: #5b7cfd !important;
}

.chartjs-render-monitor-change{
  height: 50vh !important;
  max-width: 778px;
}

.main-canvas{
  width: 100%;
}
.news-font{
  font-family: 'Manrope', sans-serif !important;
  letter-spacing: 0.7px;}

.content-padding{
  padding-left: 5vw !important;
  padding-right: 5vw !important;

}

/* navigation */
#mynav { 
  z-index: 300!important;
 }

nav{
  font-family: 'Manrope', sans-serif !important;
  letter-spacing: 0.8px;
  color: #6c6c6c;
}

.nav-link{
  /* width: auto; */
  font-size: 12px !important; 
  letter-spacing: 0.8px;
  cursor: pointer;
  animation-name: none !important;
  transition: none !important;
}



.selected{
  color: #FF6E5D !important;
}

/* case study */
.case{
  width: 100%
}
.work{
  border:  solid grey;
  border-width: 0px 1px 0px 0px;
}
 /* media relation */
.magazine{
   height:100%;
}
/* navigation */
.case{
  font-family: 'Manrope', sans-serif !important;
  letter-spacing: 0.8px;
}
.article {
    font-family: 'Manrope', sans-serif !important;
  letter-spacing: 0.8px;
}
.about-text{
  font-family: 'Trocchi', serif !important;
}
.times {
  font-family: 'Times New Roman', Times, serif !important;
}


.microsoft{
  font-family: 'Microsoft New Tai Lue' !important;
}
.prata{
  font-family: 'Manrope', sans-serif !important;
  letter-spacing: 0.8px;
}
.news-title{
  font-weight:normal !important;
}
.title{
  font-size:30px ;
}
.text-animation{
  animation-name: moveInRight;
  animation-duration: 2s;
}
.subtitle{
  font-size:18px !important;
}
.small-title{
  font-size:9px !important;
}
.median-title{
  font-size:10px !important;
}
.large-title{
  font-size:13.5px !important;
}
.font-15{
  font-size:15px !important;
}
.paragraph{
  letter-spacing: .8px;
  line-height: 1.8;
  font-family: 'Manrope', sans-serif !important;
}

.contact-title{
  font-family: 'Trocchi', serif !important;
}



/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 78%;
  right:1%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.gmail-bar {
  position: fixed;
  top: 68%;
  left:1%;
  font-size:10px !important;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.gmail-bar:hover{
  color:#FF6E5D;
}

/* Style the icon bar links */
a {
  display: block;
  text-align: center;
  padding: 5px;
  transition: all 0.3s ease;
  font-size: 20px;
  color: black !important;
  cursor: pointer;
}

/* Style the social media icons with color */
a:hover {
  color: #FF6E5D !important;
}

/* about us*/
.card {
  background-color:transparent !important;
  border: none !important;
  padding: 0 10px;
}
.work-body {
  padding: 50px 100px  !important;
}

.space-top{
  margin-top: 80px;
}
.space-bottom{
  margin-bottom: 30px;
}
/* case study */
.group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  background: #FFFFFF!important;
}

.group > .row > .col-4 {
  display: inline-block;
}

.hide{
  display: none;
}

.animation{
    transition: opacity 800ms ease 0ms;
}

/*video*/

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: left;
  padding: 0 !important;
}

/* image text */
.bottom-left {
  position: absolute;
  cursor:pointer;
  bottom: 2%;
  left: 5px;
}

.bottom-left-gallery {
  cursor:pointer;
  position: absolute;
  bottom: 8px;
  left: 16px;
}


/*button*/
.btn{
  font-family: 'Manrope', sans-serif !important;
  letter-spacing: 0.8px;
  border-radius:0 !important;
}

.btn-outline-dark:hover{
  border-color:#FF6E5D !important;
  color:#FF6E5D !important;
  background-color: transparent !important;

}
.btn-outline-warning{
  color:black !important;
  border-color:black !important;
}

.btn-outline-warning.active{
  background-color: #FFE4E1 !important;
}
.btn-outline-warning:hover{
  background-color: #FFE4E1 !important;

}
.btn-outline-warning.active:focus{
  box-shadow: none !important;
  
}

.btn-line {
  background-color: #6c6c6c;
  border: none;
  color: #ffffff;
  outline: none;
  padding: 0px 40px 0px;
  position: relative;
  
}

.btn-line:before,
.btn-line:after {
  border: 0 solid transparent;
  transition: all 0.3s;
  content: '';
  height: 0;
  position: absolute;
  width: 0px;
}


.btn-line:after {
  border-bottom: 1px solid black !important;
  bottom: 0;
  left: 0;
}

.btn-line:hover:before,
.btn-line:hover:after {
  border-color:#6c6c6c !important;
  width: 100%;
}
.btn-line.active:before,
.btn-line.active:after {
  width: 100%;
}

.btn-line{
  color:#6c6c6c !important;
  padding:0px !important;
  background-color: transparent;
}

.btn-line.active{
  color:black !important;
  pointer-events: none;
}

*:focus {
  outline: 0 !important;
}
*.active {
  outline: 0 !important;
}
*:hover {
  outline: 0 !important;
}

/* pagination */
.page-link {
  background-color: transparent !important;
  border:none !important;
  color:black!important;
  cursor: pointer;
}
.page-link:hover {
  color:#FF6E5D !important;
}
.page-item {
padding:0px !important;
border: none !important;
}

/* input */
input{
  border-width: 1px !important;
}
.short-input{
  background-color: transparent !important;
  border-color: black !important;
  border-top:0 !important;
  border-left:0 !important;
  border-right:0 !important;
  border-radius: 0 !important;
}

.large-input{
  background-color: transparent !important;
  border-color: black !important;

}
/* modal*/
.modal{
  top:5% !important;
  height:90% !important;
}
.modal-content{
  background-color: black !important;
}
.modal-backdrop.show{
  opacity: 0.9 !important;
}

/* news*/
.news{
  display:inline-block;
  height:450px;
}
.new-page-button{
  cursor: pointer;
}
.new-page-button:hover{
  color:#FF6E5D;
}
.news-img{
  position: relative;
  width: 100% !important;
  height:52%;
  z-index: 2;

}

.news-intro{
  position: absolute;
  width: 80%;
  height:40%;
  right:15px !important;
  top:48%;
  z-index: 1;
  background-color: #FFE4E1;

}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap; /* Prevent text from wrapping */
  border-left: 1px solid darkgray; /* Add a right border with desired width and color */
  padding-left: 1px; /* Optional: Add some padding to create space between text and border */
  margin-left: 10px;
  color: darkgray;
}

@keyframes moveInleft {
  0% {
      opacity: 0;
      transform: translateX(-100px);
  }

  80% {
      transform: translateX(0px);
  }
  100% {
      opacity: 1;
      transform: translate(0);
  }
}
@keyframes moveInRight {
0% {
    opacity: 0;
    transform: translateX(100px);
}

80% {
    transform: translateX(0px);
}

100% {
    opacity: 1;
    transform: translate(0);
}

}
@media (max-width: 767px) {
  .btn{
    font-family: Avenir !important;
    letter-spacing: normal;
    border-radius:0 !important;
  }
  .navbar-collapse {
    position: fixed;
    z-index: 2;
    top: 0px; /* adjust to height of navbar */
    right: 0;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 50px;
    width: 60%;
    height: 100%;
    background-color: rgb(248,227,225) !important;
  }
  nav{
    font-family: Avenir !important;
    color: #6c6c6c;
  }
  .nav-link{
    font-size: 12px !important;
    margin-top: 10px;
    animation-name: moveInRight;
    animation-duration: 3s;
  }

  .navbar-collapse.collapsing {
      right: -75%;
      transition: height 0s ease !important;
  }

  .navbar-collapse.show {
      right: 0;
      transition: right 500ms ease-in-out;
  }
  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: right 500ms ease-in-out;
  }
  .navbar-toggler.collapsed .close-icon {
    display: none;
 }

  .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    display: none;
 }
  .overlay{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.7);
    overflow-x: hidden;
    transition: 0.5s;
 }
 .close-icon{
  color: black !important;
 }
 
 /* media relation */
  .magazine{
  width: 100%;
  height:unset !important;
 }
 /* gallery */
 .freeze{
   position: fixed;
   top: 50px;
   width: 100%;
   z-index: 50;
   background-color: white;
 }
 .video-margin{
   margin-top: 10px;
 }
   /* about us */
   .space-top{
    margin-top: 30px !important;
   
    }
    .space-bottom{
      margin-bottom: 0 !important;
    }
    /* case study */
    .case{
      padding: 0%;
    }
 /* text */
 .case{
  font-family: Avenir !important;
  letter-spacing: normal;
 }
 .news-font{
  font-family: Avenir !important;
  letter-spacing: normal;
}
  .article{
    font-family:Avenir !important;
    letter-spacing: normal;

  }
  .prata{
    font-family: Avenir !important;
    letter-spacing: normal;

  }
  .news-title{
    font-weight:bold !important;
  }
  .title{
  font-size:23px ;
  text-align: center;

  }
  .subtitle{
    font-size:15px !important;
  }
  .small-title{
    font-size:6px !important;
  }
  .median-title{
    font-size:8px !important;
  }
  .large-title{
    font-size:12px !important;
    
  }
  .about-text{
    font-family: 'Trocchi', serif !important;
  }

  .paragraph{
    font-size:15px !important;
    font-family: Avenir !important;
    letter-spacing: normal;

    color:#6c6c6c !important;
    /* letter-spacing: 1px; */
  }
 

}